define('tm3/pods/components/accounting/billing/invoices/description-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        tempDescription: '',
        tempBillableType: '',

        billable: null,

        editing: false,

        isExpense: false,
        isTime: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('tempDescription', this.get('billable.description'));
            var billableType = this.get('billableTypes').findBy('id', '' + this.get('billable.billable_type_id'));

            this.set('tempBillableType', billableType);
        },


        filteredBillableTypes: Ember.computed('billableTypes.@each.{time,expense,name}', 'isExpense', 'isTime', function () {
            if (this.get('isExpense')) {
                return this.get('billableTypes').filterBy('expense', 1).filter(function (bt) {
                    return bt.get('name').toUpperCase().indexOf('TAX') < 0;
                }).sortBy('name');
            }

            if (this.get('isTime')) {
                return this.get('billableTypes').filterBy('time', 1).sortBy('name');
            }

            return this.get('billableTypes');
        }),

        actions: {
            cancel: function cancel() {
                this.set('tempDescription', this.get('billable.description'));
                var billableType = this.get('billableTypes').findBy('id', '' + this.get('billable.billable_type_id'));

                this.set('tempBillableType', billableType);
                this.set('editing', false);
            },
            save: function save() {
                var _this = this;

                if (Ember.isEmpty(this.get('tempDescription'))) {
                    this.get('notify').warning('Please enter a description');

                    return;
                }
                this.get('store').findRecord('billable', this.get('billable.id')).then(function (billable) {
                    billable.set('description', _this.get('tempDescription'));
                    billable.set('billableType', _this.get('tempBillableType'));
                    billable.save().then(function (result) {
                        _this.get('notify').success('Changes saved successfully');
                        _this.set('billable.description', result.get('description'));
                        _this.set('billable.billable_type_id', result.get('billableType.id'));
                        _this.set('billable.billable_type', result.get('billableType.name'));
                        _this.set('editing', false);
                    }).catch(function () {
                        _this.get('notify').error('Error while saving');
                    });
                });
            }
        }
    });
});