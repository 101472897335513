define('tm3/pods/components/accounting/billing/invoices/expense-qty-cut-rate-edit/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        store: Ember.inject.service(),
        notify: Ember.inject.service(),

        tempQty: '',
        tempCut: '',
        tempRate: '',

        billable: null,

        editing: false,

        isExpense: false,
        isTime: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('tempQty', this.get('billable.qty'));
            this.set('tempCut', this.get('billable.cut'));
            this.set('tempRate', this.get('billable.rate'));
        },


        actions: {
            cancel: function cancel() {
                this.set('tempQty', this.get('billable.qty'));
                this.set('tempCut', this.get('billable.cut'));
                this.set('tempRate', this.get('billable.rate'));
                this.set('editing', false);
            },
            save: function save() {
                var _this = this;

                if (Ember.isEmpty(this.get('tempQty'))) {
                    this.get('notify').warning('Please enter a quantity');

                    return;
                }

                if (Ember.isEmpty(this.get('tempRate'))) {
                    this.get('notify').warning('Please enter a rate');

                    return;
                }
                this.get('store').findRecord('billable', this.get('billable.id')).then(function (billable) {
                    billable.set('qty', _this.get('tempQty'));
                    billable.set('cut', _this.get('tempCut'));
                    billable.set('rate', _this.get('tempRate'));
                    billable.save().then(function (result) {
                        _this.get('notify').success('Changes saved successfully');
                        _this.set('billable.qty', result.get('qty'));
                        _this.set('billable.cut', result.get('cut'));
                        _this.set('billable.rate', result.get('rate'));
                        _this.set('billable.total', result.get('total'));
                        _this.set('editing', false);
                    }).catch(function () {
                        _this.get('notify').error('Error while saving');
                    });
                });
            }
        }
    });
});