define('tm3/helpers/url-for', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Helper.extend({
        router: Ember.inject.service(),

        compute: function compute(params) {
            var _get;

            return (_get = this.get('router')).urlFor.apply(_get, _toConsumableArray(params));
        }
    });
});