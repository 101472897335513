define('tm3/pods/components/billables/edit/time-entry/component', ['exports', 'tm3/pods/components/billables/edit/time-entry/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _template.default,
        showCut: false,

        billingPhrasesCaseTypeGroups: null,

        cutReasonOptions: [Ember.Object.create({ weight: 0, display: 'Training', value: 'training' }), Ember.Object.create({ weight: 1, display: 'Over Budget', value: 'over-budget' }), Ember.Object.create({ weight: 2, display: 'System issues/testing (internal)', value: 'system-issues-internal' }), Ember.Object.create({ weight: 3, display: 'Vendor / database issues', value: 'vendor-database-issues' }), Ember.Object.create({ weight: 4, display: 'Re-doing / correcting work', value: 'redoing-correcting-work' })],

        billingPhrasesFiltered: Ember.computed('item.billableType', 'matterType', 'billingPhrases', 'billingPhrasesCaseTypeGroups', function () {
            var _this = this;

            var list = this.get('billingPhrases') ? this.get('billingPhrases') : [];
            if (this.get('item.billableType')) {
                list = list.filter(function (phrase) {
                    var hasBillableType = phrase.get('billableTypeId') == _this.get('item.billableType.id') || Ember.isEmpty(phrase.get('billableTypeId'));
                    var hasMatterType = false;
                    if (Ember.isEmpty(_this.get('matterType.id'))) {
                        hasMatterType = phrase.get('caseTypeGroup.name') == 'All';
                    } else if (!Ember.isEmpty(_this.get('matterType.id')) && phrase.get('caseTypeGroup.name') == 'All') {
                        hasMatterType = true;
                    } else {
                        var caseTypeGroups = _this.get('billingPhrasesCaseTypeGroups').filter(function (caseTypeGroup) {
                            return caseTypeGroup.id == phrase.get('caseTypeGroup.id');
                        });
                        if (caseTypeGroups.length == 1) {
                            var caseTypeGroup = caseTypeGroups.get('firstObject');
                            var matterTypes = caseTypeGroup.get('caseTypeGroupMatterTypes').mapBy('matterTypeId');
                            hasMatterType = matterTypes.includes(parseInt(_this.get('matterType.id')));
                        }
                    }
                    return hasBillableType && hasMatterType;
                });
            }
            return list;
        }),

        billingPhrasesFilteredSorted: Ember.computed.sort('billingPhrasesFiltered', function (a, b) {
            var results = 0;
            if (Ember.isEmpty(a.get('billableTypeId')) && !Ember.isEmpty(b.get('billableTypeId'))) {
                results = 1;
            } else if (!Ember.isEmpty(a.get('billableTypeId')) && Ember.isEmpty(b.get('billableTypeId'))) {
                results = -1;
            } else {
                if (a.get('billableTypeId') > b.get('billableTypeId')) {
                    results = 1;
                } else if (a.get('billableTypeId') < b.get('billableTypeId')) {
                    results = -1;
                } else {
                    if (Ember.isEmpty(a.get('order')) && !Ember.isEmpty(b.get('order'))) {
                        results = 1;
                    } else if (!Ember.isEmpty(a.get('order')) && Ember.isEmpty(b.get('order'))) {
                        results = -1;
                    } else {
                        if (a.get('order') > b.get('order')) {
                            results = 1;
                        } else if (a.get('order') < b.get('order')) {
                            results = -1;
                        } else {
                            if (a.get('name') > b.get('name')) {
                                results = 1;
                            } else if (a.get('name') < b.get('name')) {
                                results = -1;
                            } else {
                                results = 0;
                            }
                        }
                    }
                }
            }
            return results;
        }),

        actions: {
            useBillingPhrase: function useBillingPhrase(billingPhrase) {
                this.set('item.description', billingPhrase.get('content'));
            }
        }
    });
});