define('tm3/pods/tasks/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        columns: [_column.default.create({ displayName: 'comment', fieldName: 'comment', order: 0 })],

        linkPath: 'matter.tasks.info',

        // filters
        createdById: [],
        caseOwnerIds: [],
        assignedToId: [],
        dueFrom: '',
        dueTo: '',
        dueOn: '',
        noDue: false,
        county: '',
        city: '',
        state: '',
        matterTypeId: '',
        onlyUrgent: false,
        completedOnly: false,
        showWaiting: false,
        applyFilter: 0,
        showCompletedCases: false,

        sortField: '-created_on',
        sortOrder: 'desc',
        quickSearchField: 'comment',
        quickSearch: null,

        with: 'task_lists,task_has_events',
        useTableSettings: false,

        titleParams: Ember.computed('actions.sortBy', 'sortField', function () {
            return {
                sortByAction: this.actions.sortBy.bind(this),
                sortField: this.get('sortField')
            };
        }),

        // Check if all of the task filters are empty
        isFilterEmpty: Ember.computed('createdById', 'caseOwnerIds', 'assignedToId', 'matterType', 'dueFrom', 'dueOn', 'county', 'state', 'onlyUrgent', 'showCompletedCases', 'completedOnly', 'showWaiting', function () {
            return Ember.isEmpty(this.get('createdById')) && Ember.isEmpty(this.get('caseOwnerIds')) && Ember.isEmpty(this.get('assignedToId')) && Ember.isEmpty(this.get('matterType')) && Ember.isEmpty(this.get('dueFrom')) && Ember.isEmpty(this.get('dueOn')) && Ember.isEmpty(this.get('county')) && Ember.isEmpty(this.get('state')) && !this.get('onlyUrgent') && !this.get('showCompletedCases') && !this.get('completedOnly') && !this.get('showWaiting');
        }),

        actions: {
            applyFilter: function applyFilter() {
                this.set('page', 1);
            },
            sortBy: function sortBy(newSort) {
                this.set('sortField', newSort.field);
            }
        }
    });
});