define('tm3/pods/components/accounting/billing/invoices/time-entry-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        tagName: '',
        showCut: false,
        selectedItemForQtyCutEdit: null,
        showDeleteConfirmation: false,
        massEditCut: false,
        previewDocument: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var currentBudgetId = this.get('timeEntry.billables.time.budget_id');
            this.set('qty', parseFloat(this.get('timeEntry.billables.time.qty')));
            this.set('cut', parseFloat(this.get('timeEntry.billables.time.cut')));
            this.set('selectedBudgetId', currentBudgetId);
        },

        actions: {
            onBudgetEdit: function onBudgetEdit() {
                var currentBudgetId = this.get('timeEntry.billables.time.budget_id');
                this.set('selectedBudgetId', currentBudgetId);
            },
            onBudgetCancel: function onBudgetCancel() {
                var currentBudgetId = this.get('timeEntry.billables.time.budget_id');
                this.set('selectedBudgetId', currentBudgetId);
            },
            saveBudget: function saveBudget() {
                var newBudgetId = this.get('selectedBudgetId');
                this.sendAction('saveBudget', this.get('timeEntry'), newBudgetId);
            },
            budgetChange: function budgetChange(val) {
                var newVal = val ? val.id : null;
                this.set('selectedBudgetId', newVal);
            },
            changeCut: function changeCut(newCut) {
                var originalQty = parseFloat(this.get('timeEntry.billables.time.qty'));
                var originalCut = parseFloat(this.get('timeEntry.billables.time.cut'));
                var diffCut = originalCut - parseFloat(newCut);
                var newQty = originalQty + diffCut;
                if (newQty < 0) {
                    this.set('cut', originalCut);
                    this.set('qty', originalQty);
                    this.get('notify').warning('Quantity can not be negative');
                    return;
                }
                this.set('cut', parseFloat(newCut));
                this.set('qty', originalQty + diffCut);
                var timeEntry = this.get('timeEntry');
                timeEntry.qty = this.get('qty');
                timeEntry.cut = this.get('cut');
            },
            editQtyCut: function editQtyCut() {
                if (this.get('canEdit')) {
                    this.sendAction('editQtyCut', this.get('timeEntry'));
                }
            },
            cancelQtyCutEdit: function cancelQtyCutEdit() {
                this.sendAction('cancelQtyCutEdit');
            },
            saveQtyCut: function saveQtyCut(qty, cut) {
                this.sendAction('saveQtyCut', this.get('timeEntry'), qty, cut);
            },
            previewDocument: function previewDocument(id, event) {
                var _this = this;

                event.preventDefault();

                // some magic (sorry) to figure out the colspan value for the table cell that will hold the doc preview iframe
                var td = event.target.closest('td.document-preview-stop-col');
                var colspan = Array.from(td.parentElement.children).findIndex(function (e) {
                    return e.classList.contains('document-preview-stop-col');
                });
                this.set('docPreviewColumnSpan', colspan);

                this.get('store').queryRecord('document', {
                    resource_id: id,
                    show_receipts: true
                }).then(function (document) {
                    return _this.set('previewDocument', document);
                });
            }
        },
        selectedBudgetId: null,
        currentSelectedBudget: Ember.computed('budgets.[]', 'selectedBudgetId', {
            get: function get() {
                var budgets = this.get('budgets');
                if (budgets) {
                    var selectedBudgetId = this.get('selectedBudgetId');
                    return budgets.findBy('id', selectedBudgetId);
                }
            }
        }),
        currentBudget: Ember.computed('budgetsWithDisabled.[]', 'timeEntry.billables.time.budget_id', {
            get: function get() {
                var budgets = this.get('budgetsWithDisabled');
                if (budgets) {
                    var currentBudgetId = this.get('timeEntry.billables.time.budget_id');
                    return budgets.findBy('id', '' + currentBudgetId);
                }
            }
        })
    });
});