define('tm3/pods/matter/tasks/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentMatter: Ember.inject.service(),

        queryParams: ['filterAssignedTo', 'filterDueOn', 'expandedTaskListIds', 'filterStatus', 'filterCompleted'],

        matter: Ember.computed.alias('currentMatter.matter'),

        filterAssignedTo: 'all',
        filterDueOn: 'all',
        filterStatus: 'all',
        expandedTaskListIds: [],
        selectedTaskListIds: [],

        taskLists: Ember.computed.alias('model.taskLists'),
        employees: Ember.computed.alias('model.employees'),
        listTemplateGroups: Ember.computed.alias('model.listTemplateGroups'),

        dueOnFilters: [{ value: 'all', display: 'Anytime' }, { value: 'overdue', display: 'In the past (overdue)' }],
        statusFilters: [{ value: 'all', display: 'All' }, { value: 'not_started', display: 'Only Not Started' }, { value: 'waiting', display: 'Only Waiting' }]
    });
});